import React from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components';

import { formatCurrencyAmount, getLocalizedTexts } from '../../Locales';
import { useApolloClient } from '../../api/config';
import { ApolloClient } from '../../api/graphql/client';
import { getUserId } from '../../lib/common/cognito';
import { useUserInfo } from '../../lib/common/userInfo';
import { Feature, updateFeatureMapVar, useFeatureMap } from '../../lib/features/buildFeatureMap';
import {
    MerchantIdToMerchantNameMap,
    MerchantProductOffer,
    ProductDiscoveryConversationMessageWithProducts,
    useHandleConnectionWithAssistant,
    useMerchantIdToMerchantNameMap,
    useResetConversation,
    useScrollToBottom,
    useSendMessage,
    useSuggestedProductsDebugLogs,
} from '../../components/products/productDiscovery';
import { font } from '../../style/text';
import { AnimatedGradientBackground } from '../../components/common/GradientBackground';
import GradientText from '../../components/common/GradientText';
import { logUserEventUtil } from '../../lib/events/userEvents';
import { CLICKABLE_ELEMENT_CSS_MIXIN, NO_SCROLLBAR_CSS_MIXIN } from '../../style/styleConstants';
import Thumb from '../../style/reactSvg/Thumb';
import { deviceWidthMeasurements } from '../../style/size';
import { isMobileDevice, useViewportSize } from '../../style/utils';
import { colors } from '../../tokens/colors/colors';
import { Size } from '../../tokens/measurements/size';

const editIcon = '/assets/images/icons/edit.svg';
const settingsGear = '/assets/images/icons/settings-gear.svg';
const logo = '/assets/images/logos/lightning-round.svg';
const arrowHeadUp = '/assets/images/icons/arrow-head-up-white.svg';
const productLinkArrow = '/assets/images/icons/product-link-arrow.svg';
const chevronGrayDown = '/assets/images/icons/chevron-gray-down-new.svg';

function ProductDiscoveryPage() {
    const apolloClient = useApolloClient();
    React.useEffect(() => {
        document.title = 'Joko AI';
    }, []);
    React.useEffect(() => {
        if (apolloClient) updateFeatureMapVar(apolloClient);
    }, [apolloClient]);
    const featureMap = useFeatureMap();
    if (featureMap && !!featureMap?.[Feature.productDiscoveryDesktopBrowserExtension] && Platform.OS === 'web') {
        return <ProductDiscoveryContent />;
    }
    return <NotAvailableText>{'Coming soon...'}</NotAvailableText>;
}

export default ProductDiscoveryPage;

function ProductDiscoveryContent() {
    const [messages, setMessages] = React.useState<ProductDiscoveryConversationMessageWithProducts[]>([]);
    const [firstMessageToSend, setFirstMessageToSend] = React.useState<string | undefined>(undefined);
    const [inputText, setInputText] = React.useState<string>('');
    const [conversationId, setConversationId] = React.useState<string>('');
    const webSocketRef = React.useRef<WebSocket | undefined>(undefined);
    const userId = React.useMemo(() => getUserId(), []);
    const apolloClient = useApolloClient();
    let messageIdCounterRef = React.useRef(0);
    const [userFeedbackMap, setUserFeedbackMap] = React.useState<{ [id: string]: 'positive' | 'negative' | null }>({});
    const [isAssistantThinking, setIsAssistantThinking] = React.useState(false);
    const messagesListRef = React.useRef<HTMLDivElement | null>(null);
    const { shouldShowScrollToBottomButton, setShouldShowScrollToBottomButton, handleScroll } = useScrollToBottom({
        messages,
        messagesListRef,
    });
    const { forceWebSocketReconnection } = useHandleConnectionWithAssistant({
        userId,
        webSocketRef,
        messageIdCounterRef,
        setMessages,
        setIsAssistantThinking,
        conversationId,
        setConversationId,
    });
    const sendMessage = useSendMessage({
        userId,
        webSocketRef,
        messageIdCounterRef,
        setMessages,
        conversationId,
        inputText,
        setInputText,
        forceWebSocketReconnection,
        messagesListRef,
        setIsAssistantThinking,
        firstMessageToSend,
        setFirstMessageToSend,
    });
    const resetConversation = useResetConversation({
        conversationId,
        setMessages,
        messageIdCounterRef,
        setIsAssistantThinking,
        setFirstMessageToSend,
        forceWebSocketReconnection,
        setShouldShowScrollToBottomButton,
        setUserFeedbackMap,
    });
    const merchantIdToMerchantNameMap = useMerchantIdToMerchantNameMap({ apolloClient, messages });
    useSuggestedProductsDebugLogs({ messages });
    const { height: viewportHeight } = useViewportSize();
    const renderMessage = getRenderMessage({
        apolloClient,
        merchantIdToMerchantNameMap,
        conversationId,
        userFeedbackMap,
        setUserFeedbackMap,
    });
    return (
        <MainContainer {...{ viewportHeight }}>
            <Header {...{ resetConversation, conversationId }} />
            {messages.length > 0 ? (
                <MessagesContainer ref={messagesListRef} onScroll={handleScroll}>
                    <MessagesInnerContainer>
                        {messages.map((message) => renderMessage({ item: message }))}
                    </MessagesInnerContainer>
                </MessagesContainer>
            ) : (
                <LandingPageContent {...{ sendMessage }} />
            )}
            {shouldShowScrollToBottomButton ? <ScrollToBottomButton {...{ messagesListRef }} /> : null}
            <InputBar {...{ inputText, setInputText, sendMessage, isAssistantThinking }} />
        </MainContainer>
    );
}

function getRenderMessage({
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}): React.FC<{ item: ProductDiscoveryConversationMessageWithProducts }> {
    return ({ item: message }) => {
        if (message.role === 'user') return <UserMessage {...{ message }} key={message.id} />;
        return (
            <AssistantMessage
                {...{
                    message,
                    apolloClient,
                    merchantIdToMerchantNameMap,
                    conversationId,
                    userFeedbackMap,
                    setUserFeedbackMap,
                }}
                key={message.id}
            />
        );
    };
}

function Header({ resetConversation, conversationId }: { resetConversation: () => void; conversationId: string }) {
    const featureMap = useFeatureMap();
    const shouldShowConversationId = !!featureMap?.[Feature.showConversationIdInProductDiscovery];
    return (
        <HeaderContainer>
            <EditImageContainer onClick={() => resetConversation()}>
                <HeaderImage src={editIcon} />
            </EditImageContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <BetaVersionText>{`Beta${
                    shouldShowConversationId && conversationId
                        ? ' - Conversation Id:' + (isMobileDevice ? '\n' : ' ') + conversationId
                        : ''
                }`}</BetaVersionText>
                <HeaderImage src={settingsGear} />
            </div>
        </HeaderContainer>
    );
}

function LandingPageContent({ sendMessage }: { sendMessage: (suggestedMessage?: string) => void }) {
    const [userInfo] = useUserInfo();
    const { width: viewportWidth } = useViewportSize();
    const texts = getLocalizedTexts().productDiscovery.landingPage;
    const displayedSuggestions = getDisplayedSuggestions(viewportWidth);
    return (
        <LandingPageContainer>
            <LandingPageInnerContainer>
                <JokoLogoLandingPageImage src={logo} />
                {userInfo.firstName ? (
                    <LandingPageTitleText>
                        {texts.titleWithFirstName({ firstName: userInfo.firstName })}
                    </LandingPageTitleText>
                ) : (
                    <LandingPageTitleText> {texts.titleWithoutFirstName}</LandingPageTitleText>
                )}
                <LandingPageSubtitleText>{texts.subtitle}</LandingPageSubtitleText>
                <UserMessageSuggestionsContainer>
                    {displayedSuggestions.map((suggestedMessage) => (
                        <UserMessageSuggestionsText key={suggestedMessage} onClick={() => sendMessage(suggestedMessage)}>
                            {suggestedMessage}
                        </UserMessageSuggestionsText>
                    ))}
                </UserMessageSuggestionsContainer>
            </LandingPageInnerContainer>
        </LandingPageContainer>
    );
}

function getDisplayedSuggestions(viewportWidth: number) {
    const texts = getLocalizedTexts().productDiscovery;
    return window.innerWidth < 380
        ? texts.landingPage.userMessageSuggestionsMobile.slice(0, 5)
        : window.innerWidth < 500
        ? texts.landingPage.userMessageSuggestionsMobile
        : viewportWidth < 740
        ? texts.landingPage.userMessageSuggestionsDesktop.slice(0, 6)
        : texts.landingPage.userMessageSuggestionsDesktop;
}

function ScrollToBottomButton({ messagesListRef }: { messagesListRef: React.RefObject<any> }) {
    const handleScrollToBottom = () => {
        messagesListRef.current?.scrollTo({ top: messagesListRef.current.scrollHeight, behavior: 'smooth' });
    };
    return (
        <ScrollToBottomButtonContainer>
            <ScrollToBottomButtonCircleContainer onClick={handleScrollToBottom}>
                <ChevronDownImage src={chevronGrayDown} />
            </ScrollToBottomButtonCircleContainer>
        </ScrollToBottomButtonContainer>
    );
}

function UserMessage({ message }: { message: ProductDiscoveryConversationMessageWithProducts }) {
    return (
        <UserMessageContainer>
            <MessageUserText>{message.content}</MessageUserText>
        </UserMessageContainer>
    );
}

function AssistantMessage({
    message,
    apolloClient,
    merchantIdToMerchantNameMap,
    conversationId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    message: ProductDiscoveryConversationMessageWithProducts;
    apolloClient: ApolloClient | undefined;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
    conversationId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}) {
    const texts = getLocalizedTexts();
    return (
        <AssistantMessageContainer>
            <AssistantMessageTextContainer>
                <JokoLogoMessageImage src={logo} />
                {message.content ? (
                    <MessageAssistantText>{message.content}</MessageAssistantText>
                ) : (
                    // TODO: refactor to remove React Native components
                    <GradientText text={texts.productDiscovery.thinkingPlaceholder} fontSize={isMobileDevice ? 16 : 18} />
                )}
            </AssistantMessageTextContainer>
            {message.merchantProductOffers?.length ? (
                <SuggestedMerchantProductOffers
                    {...{ offers: message.merchantProductOffers, merchantIdToMerchantNameMap }}
                />
            ) : null}
            {message.merchantProductOffers?.length && apolloClient ? (
                <UserFeedback
                    {...{ client: apolloClient, conversationId, messageId: message.id, userFeedbackMap, setUserFeedbackMap }}
                />
            ) : null}
        </AssistantMessageContainer>
    );
}

function SuggestedMerchantProductOffers({
    offers,
    merchantIdToMerchantNameMap,
}: {
    offers: MerchantProductOffer[];
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
}) {
    return (
        <SuggestedMerchantProductOffersContainer>
            {offers.map((offer) => (
                <MerchantProductOfferCard key={offer.merchantProductOfferId} {...{ offer, merchantIdToMerchantNameMap }} />
            ))}
        </SuggestedMerchantProductOffersContainer>
    );
}

function MerchantProductOfferCard({
    offer,
    merchantIdToMerchantNameMap,
}: {
    offer: MerchantProductOffer;
    merchantIdToMerchantNameMap: MerchantIdToMerchantNameMap;
}) {
    const [isImageLoading, setIsImageLoading] = React.useState(true);
    if (!offer.processedWebpageUrl) return null;
    const merchantNameToDisplay =
        offer.merchantId && merchantIdToMerchantNameMap[offer.merchantId]
            ? merchantIdToMerchantNameMap[offer.merchantId]
            : offer.contextualInformation.originalMerchantName;
    return (
        <ProductCardContainer onClick={() => window.open(offer.processedWebpageUrl as string, '_blank')}>
            <ProductCardContent>
                {isImageLoading ? (
                    <ProductImageContainer {...{ isImageLoading, isImagePlaceholder: true }}>
                        {/* TODO: refactor to remove React Native components */}
                        <AnimatedGradientBackground />
                    </ProductImageContainer>
                ) : null}
                <ProductImageContainer {...{ isImageLoading }}>
                    <ProductImage src={offer.mainImageUrls.defaultSize} onLoad={() => setIsImageLoading(false)} />
                </ProductImageContainer>
                <ProductTextContainer>
                    <ProductPriceText>
                        {formatCurrencyAmount(offer.priceInformation.displayPriceAmount.valueInCents / 100)}
                    </ProductPriceText>
                    <ProductTitleText>{offer.originalTitle}</ProductTitleText>
                    {merchantNameToDisplay ? (
                        <ProductLinkContainer>
                            <ProductLinkText>{merchantNameToDisplay}</ProductLinkText>
                            <LinkArrowImage src={productLinkArrow} />
                        </ProductLinkContainer>
                    ) : null}
                </ProductTextContainer>
            </ProductCardContent>
        </ProductCardContainer>
    );
}

function InputBar({
    inputText,
    setInputText,
    sendMessage,
    isAssistantThinking,
}: {
    inputText: string;
    setInputText: (text: string) => void;
    sendMessage: (suggestedMessage?: string) => void;
    isAssistantThinking: boolean;
}) {
    const texts = getLocalizedTexts().productDiscovery;
    const [isSendButtonEnabled, setIsSendButtonEnabled] = React.useState(false);
    React.useEffect(() => {
        if (isAssistantThinking) setIsSendButtonEnabled(false);
        else setIsSendButtonEnabled(inputText.trim().length > 0);
    }, [inputText, isAssistantThinking]);
    return (
        <InputBarContainer>
            <InputText
                value={inputText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputText(event.target.value)}
                placeholder={texts.inputPlaceholder}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && isSendButtonEnabled) sendMessage();
                }}
            />
            <SendButtonContainer
                {...{ isSendButtonEnabled }}
                onClick={() => {
                    if (isSendButtonEnabled) sendMessage();
                }}>
                <ArrowSendButtonImage src={arrowHeadUp} />
            </SendButtonContainer>
        </InputBarContainer>
    );
}

function UserFeedback({
    client,
    conversationId,
    messageId,
    userFeedbackMap,
    setUserFeedbackMap,
}: {
    client: ApolloClient;
    conversationId: string;
    messageId: string;
    userFeedbackMap: { [id: string]: 'positive' | 'negative' | null };
    setUserFeedbackMap: React.Dispatch<React.SetStateAction<{ [id: string]: 'positive' | 'negative' | null }>>;
}) {
    const texts = getLocalizedTexts().productDiscovery;
    const onClickPositiveFeedback = () => {
        if (userFeedbackMap[messageId] === 'positive') return;
        setUserFeedbackMap((prevFeedback) => ({
            ...prevFeedback,
            [messageId]: 'positive',
        }));
        logUserEventUtil(client, {
            type: 'sentProductDiscoverySuggestionsFeedback',
            payload: { conversationId, messageId, feedbackType: 'positive' },
        });
    };
    const onClickNegativeFeedback = () => {
        if (userFeedbackMap[messageId] === 'negative') return;
        setUserFeedbackMap((prevFeedback) => ({
            ...prevFeedback,
            [messageId]: 'negative',
        }));
        logUserEventUtil(client, {
            type: 'sentProductDiscoverySuggestionsFeedback',
            payload: { conversationId, messageId, feedbackType: 'negative' },
        });
    };
    return (
        <UserFeedbackContainer>
            <FeedbackQuestionText>{texts.feedbackQuestion}</FeedbackQuestionText>
            <ThumbImageContainer
                style={{ marginLeft: isMobileDevice ? 6 : 8, marginRight: 7 }}
                onClick={onClickPositiveFeedback}>
                <Thumb isSelected={userFeedbackMap[messageId] === 'positive'} orientation="up" />
            </ThumbImageContainer>
            <ThumbImageContainer onClick={onClickNegativeFeedback}>
                <Thumb isSelected={userFeedbackMap[messageId] === 'negative'} orientation="down" />
            </ThumbImageContainer>
        </UserFeedbackContainer>
    );
}

type ProductDiscoveryContentWidthScale = '474px' | '630px' | '710px';

type ProductDiscoveryContentWidth = Partial<Record<Size, ProductDiscoveryContentWidthScale>>;

const productDiscoveryContentWidths: ProductDiscoveryContentWidth = {
    medium: '474px',
    large: '630px',
    xxLarge: '710px',
};

const MainContainer = styled.div<{ viewportHeight: number }>`
    display: flex;
    flex-direction: column;
    height: ${({ viewportHeight }) => viewportHeight}px;
`;

const HeaderContainer = styled.div`
    display: flex;
    height: 48px;
    flex-direction: row;
    margin-bottom: 16px;
    margin-left: 64px;
    margin-right: 64px;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        min-height: 36px;
        max-height: 36px;
        margin-left: 16px;
        margin-right: 16px;
        padding: 8px 0px;
    }
`;

const EditImageContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    border-radius: 96px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:active {
        background-color: ${colors.background.subtle};
    }

    @media (min-width: ${deviceWidthMeasurements.small}) {
        &:hover {
            background-color: ${colors.background.subtle};
            cursor: pointer;
        }
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        height: 36px;
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
`;

const LandingPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    padding-top: 112px;
    margin-bottom: 16px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: ${deviceWidthMeasurements.small}) & (max-height: 600px) {
        padding-top: 24px;
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: calc(100% - 32px);
        padding-top: 0px;
        margin: 0px 16px;
    }

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const LandingPageInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${productDiscoveryContentWidths.xxLarge};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: ${productDiscoveryContentWidths.large};
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: ${productDiscoveryContentWidths.medium};
    }
`;

const MessagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: calc(100% - 32px);
        padding: 0 16px;
    }

    ${NO_SCROLLBAR_CSS_MIXIN}
`;

const MessagesInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${productDiscoveryContentWidths.xxLarge};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: ${productDiscoveryContentWidths.large};
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: ${productDiscoveryContentWidths.medium};
    }
`;

const UserMessageSuggestionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: stretch;
    gap: 16px 16px;
`;

const UserMessageContainer = styled.div`
    max-width: 663px;
    align-self: flex-end;
    padding: 16px 24px 16px 24px;
    margin-bottom: 24px;
    border-radius: 12px;
    background-color: ${colors.background.light};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 256px;
    }
`;

const AssistantMessageContainer = styled.div`
    flex-direction: column;
    align-self: flex-start;
`;

const AssistantMessageTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 32px);
    align-items: flex-start;
    margin-bottom: 32px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-bottom: 16px;
    }
`;

const SuggestedMerchantProductOffersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(120px, 0fr)
    ); // This ensures items should not expand to fill additional space, which effectively fixes the width of each grid item to 120px
    grid-row-gap: 16px;
    justify-content: space-between;
    margin-left: 44px;
    margin-bottom: 32px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        display: flex;
        position: relative;
        flex-direction: row;
        width: calc(100vw - 56px - 16px);
        gap: 8px;
        padding-left: 56px;
        padding-right: 16px;
        margin-bottom: 32px;
        transform: translateX(-60px); /* Adjust back to align to viewport edges */
        overflow-x: auto;

        ${NO_SCROLLBAR_CSS_MIXIN}
    }
`;

const ProductCardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 120px;
    background-color: ${colors.background.default};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    @media (min-width: ${deviceWidthMeasurements.small}) {
        &:hover {
            z-index: 1;
            border-radius: 16px;
            border: 1px solid ${colors.background.subtle};
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
            transform: scale(1.375, 1.2);
        }
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 144px;
    }
`;

const ProductCardContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductImageContainer = styled.div<{ isImageLoading: boolean; isImagePlaceholder?: boolean }>`
    display: ${({ isImageLoading, isImagePlaceholder }) => (isImageLoading && !isImagePlaceholder ? 'none' : 'flex')};
    align-items: center;
    width: 120px;
    height: 120px;
    margin-bottom: 12px;

    @media (min-width: ${deviceWidthMeasurements.small}) {
        ${ProductCardContainer}:hover & {
            margin-bottom: 0px;
            width: calc(100% - 16px);
            padding-top: 8px;
            align-self: center;
        }
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 144px;
        height: 144px;
    }
`;

const ProductTextContainer = styled.div`
    @media (min-width: ${deviceWidthMeasurements.small}) {
        ${ProductCardContainer}:hover & {
            width: calc(135% - 16px);
            align-self: center;
            transform: scale(0.727, 0.833); // We reverse the scaling of the parent to keep the text at the same size
        }
    }
`;

const ProductLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 4px;
    }
`;

const UserFeedbackContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-left: 44px;
    margin-bottom: 28px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-left: 36px;
    }
`;

const ScrollToBottomButtonContainer = styled.div`
    position: fixed;
    bottom: 104px;
    align-self: center;
    width: 100%;
    max-width: ${productDiscoveryContentWidths.xxLarge};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: ${productDiscoveryContentWidths.large};
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: ${productDiscoveryContentWidths.medium};
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 343px;
        min-width: 100%;
        margin-left: 32px;
    }
`;

const ScrollToBottomButtonCircleContainer = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 96px;
    border-width: 1px;
    border-color: #e3e3e3;
    border-style: solid;
    background-color: ${colors.background.default};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 26px;
        height: 26px;
    }
`;

const InputBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    height: 64px;
    min-height: 64px;
    max-width: calc(${productDiscoveryContentWidths.xxLarge} - 16px - 24px); // We need to remove the padding
    width: calc(100% - 64px);
    padding-left: 16px;
    padding-right: 24px;
    margin-bottom: 24px;
    border-radius: 16px;
    background-color: ${colors.background.subtle};

    @media (max-width: ${deviceWidthMeasurements.large}) {
        max-width: calc(${productDiscoveryContentWidths.large} - 16px - 24px);
    }
    @media (max-width: ${deviceWidthMeasurements.medium}) {
        max-width: calc(${productDiscoveryContentWidths.medium} - 16px - 24px);
    }
    @media (max-width: ${deviceWidthMeasurements.small}) {
        height: 44px;
        min-height: 44px;
        margin-bottom: 16px;
        padding-left: 12px;
        padding-right: 16px;
    }
`;

const SendButtonContainer = styled.div<{ isSendButtonEnabled: boolean }>`
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 96px;
    justify-content: center;
    align-items: center;
    background-color: ${({ isSendButtonEnabled }) =>
        isSendButtonEnabled ? colors.background.primary : colors.background.tertiary};
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const ThumbImageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const HeaderImage = styled.img`
    width: 24px;
    height: 24px;
    ${CLICKABLE_ELEMENT_CSS_MIXIN}
`;

const JokoLogoLandingPageImage = styled.img`
    width: 54px;
    height: 54px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 48px;
    }
`;

const JokoLogoMessageImage = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    flex-shrink: 0; // Prevent the logo from shrinking with the text

    @media (max-width: ${deviceWidthMeasurements.small}) {
        width: 28px;
        height: 28px;
    }
`;

const ArrowSendButtonImage = styled.img`
    width: 16px;
    height: 16px;
    object-fit: contain;
`;

const ProductImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
    transform-origin: center;

    @media (min-width: ${deviceWidthMeasurements.small}) {
        ${ProductCardContainer}:hover & {
            max-height: calc(100% - 20px);
            transform: scale(1, 1.15);
        }
    }
`;

const LinkArrowImage = styled.img`
    width: 11px;
    height: 11px;
    object-fit: contain;
`;

const ChevronDownImage = styled.img`
    width: 12px;
    height: 12px;
    object-fit: contain;
`;

const NotAvailableText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
`;

const BetaVersionText = styled.div`
    margin-right: 12px;
    font-size: 14px;
    font-family: ${font.ambitRegular};
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 12px;
        text-align: right;
        white-space: pre-wrap;
    }
`;

const LandingPageTitleText = styled.div`
    margin-top: 32px;
    font-size: 32px;
    font-family: ${font.ambitBold};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        margin-top: 28px;
        font-size: 22px;
    }
`;

const LandingPageSubtitleText = styled.div`
    margin-top: 24px;
    margin-bottom: 32px;
    font-size: 18px;
    text-align: center;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        max-width: 360px;
        font-size: 16px;
        margin-top: 8px;
        margin-bottom: 24px;
    }
`;

const UserMessageSuggestionsText = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    ${CLICKABLE_ELEMENT_CSS_MIXIN}

    &:hover {
        background-color: ${colors.background.subtle};
    }
`;

const ProductPriceText = styled.div`
    font-size: 18px;
    font-family: ${font.ambitBold};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
    }
`;

const ProductTitleText = styled.div`
    display: -webkit-box;
    margin-top: 8px;
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 14px;
    }
`;

const ProductLinkText = styled.div`
    display: -webkit-box;
    margin-right: 8px;
    font-size: 14px;
    font-family: ${font.ambitSemiBold};
    color: ${colors.content.secondary};
    text-decoration-line: underline;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const FeedbackQuestionText = styled.div`
    font-size: 16px;
    font-family: ${font.ambitSemiBold};
    line-height: 21px;
    color: ${colors.content.secondary};

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 14px;
    }
`;

const MessageUserText = styled.div`
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    line-height: 21px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
    }
`;

const MessageAssistantText = styled.div`
    margin-top: 4px;
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    line-height: 25px;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
        line-height: 22px;
    }
`;

const InputText = styled.input`
    flex: 1;
    height: 32px;
    max-width: 100%;
    margin-right: 16px;
    font-size: 18px;
    font-family: ${font.ambitSemiBold};
    border: none;
    background-color: transparent;

    @media (max-width: ${deviceWidthMeasurements.small}) {
        font-size: 16px;
        line-height: 19px;
    }
`;
